<template>
    <TabView>
        <TabPanel header="Sales Review">
            <SalesReview :id="id"/>
        </TabPanel>
        <TabPanel header="Business Review">
            <BusinessReview :id="id"/>
        </TabPanel>
        <TabPanel header="Admin Work">
            <AdminWork :id="id"/>
        </TabPanel>
        <TabPanel header="Join Visit">
            <JoinVisit :id="id"/>
        </TabPanel>
        <TabPanel header="Field Tracking">
            <FieldTracking :id="id"/>
        </TabPanel>
        <TabPanel header="Account Development">
            <AccountDevelopment :id="id"/>
        </TabPanel>
    </TabView>
</template>

<script>
import SalesReview from './SalesReview.vue';
import BusinessReview from './BusinessReview.vue';
import AdminWork from './AdminWork.vue';
import JoinVisit from './JoinVisit.vue';
import FieldTracking from './FieldTracking.vue';
import AccountDevelopment from './AccountDevelopment.vue';

export default {
    props: [
		  "id",
    ],
	components: {
		'SalesReview': SalesReview,
		'BusinessReview': BusinessReview,
		'AdminWork': AdminWork,
		'JoinVisit': JoinVisit,
		'FieldTracking': FieldTracking,
		'AccountDevelopment': AccountDevelopment,
	},
}
</script>