<template>
    <BlockUI :blocked="loadingCard">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <Calendar :manualInput="false" v-model="period"  view="month" dateFormat="mm-yy"  icon="pi pi-calendar" :showIcon="true"/>
            </div>
        </div>
        <FullCalendar :options="options" ref="fullCalendar">
            <template v-slot:eventContent='arg'>
                <div class="text-center">
                    <span class="text-3xl font-bold text-purple-500">{{ arg.event.extendedProps.desc_detail.total }}</span>
                    <hr :style="{ height: '1em', backgroundColor: arg.event.extendedProps.desc_detail.presence_color }">
                </div>
            </template>
        </FullCalendar>
    </BlockUI>

     <Dialog v-model:visible="detailItemDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }" header="Data Detail" :modal="true" :maximizable="true">
        <div>
           <Message severity="info" :closable="false" v-if="item.activity">{{item.activity}}</Message>
           <Message severity="warn" :closable="false" v-else>Tidak Ada Aktifitas</Message> 
        </div>
     </Dialog>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';

export default {
    props: [
		"id",
    ],
	components: {
		'FullCalendar': FullCalendar,
	},
    data() {
        return {
            loading: false,
            loadingCard: false,

            //detail
            detailItemDialog: false,

            item: null,

            date: null,
			options: {
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                events: null,
                eventClick: this.handleEventClick
            },
            period: new Date(),
        }
    },
    mounted() {
        this.getData();
        this.$store.commit('setErrors', {});
    },
    watch: {
        period(){
            this.getData();
        }
    },
    computed:{
        period_label(){ 
            return moment(this.period, 'YYYY-MM').format('YYYY-MM'); 
        },
    },
    methods:{
        // CALENDAR
        getData(){
            this.loadingCard=true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/mjp',
                params: {
                    "userid": this.id,
                    "periode": this.period_label
                }
            })
            .then(res => {
                this.options.events = res.data.data;
                this.goToDate();

                this.loadingCard= false;
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
        goToDate() {
            const calendarApi = this.$refs.fullCalendar.getApi();

            const targetDate = this.period_label; 

            // Beralih ke tanggal tertentu
            calendarApi.gotoDate(targetDate);
        },
        handleEventClick(info) {
            const eventObj = info.event;

            this.item = eventObj.extendedProps.desc_detail;

            this.detailItemDialog = true;
        },
        hideDialog() {
            this.detailItemDialog = false;
        },
    }
}
</script>