<template>
    <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
        <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <div>
                    <h4>Join Visit</h4>
                    <!-- <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                    <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" /> -->
                </div>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                </span>
            </div>
        </template>
            <Column field="number" header="No." :style="{width:'20px'}" >
                <template #body="slotProps">
                {{ slotProps.data.number }}
                </template>
            </Column>
            <Column field="jv_date" header="Tanggal">
                <template #body="slotProps">
                    {{slotProps.data.jv_date}}
                </template>
            </Column>
            <Column field="username" header="Username">
                <template #body="slotProps">
                    {{slotProps.data.username}}
                </template>
            </Column>
            <Column field="name" header="Nama">
                <template #body="slotProps">
                    {{slotProps.data.name}}
                </template>
            </Column>
            <Column field="distributor_name" header="Distributor">
                <template #body="slotProps">
                    {{slotProps.data.distributor_name}}
                </template>
            </Column>
            <Column field="salesman_name" header="Salesman">
                <template #body="slotProps">
                    {{slotProps.data.salesman_name}}
                </template>
            </Column>
            <Column field="average_score" header="Skor Rata-Rata">
                <template #body="slotProps">
                    {{slotProps.data.average_score}}
                </template>
            </Column>
        <template #empty>
            No data found.
        </template>
        <template #loading>
            Loading data. Please wait.
        </template>
        <template #footer>
            In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
        </template>
    </DataTable>

    <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
        <template>
            {{totalItemsCount}}
        </template>
    </Paginator>
</template>

<script>
import moment from "moment";
import { numberingDatatable } from "../../../../utils/helpers";

export default {
    props: [
		  "id",
    ],
    data() {
        return {
            loading: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    methods:{
        formatDate(value) {
            if (value) {
                return moment.utc(value).format("DD-MM-YYYY");
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/join-visit',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "userid" : this.id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
    }
    
}
</script>