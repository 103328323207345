<template>
    <BlockUI :blocked="loadingCard">
        <div class="grid grid-nogutter border-top-1 surface-border pt-2">
            <div class="col-12 md:col-6 p-3">
                <div class="text-500 font-medium mb-2">
                    Username
                </div>
                <div class="text-900">
                    {{user?.username}}
                </div>
            </div>
            <div class="col-12 md:col-6 p-3">
                <div class="text-500 font-medium mb-2">
                    Nama
                </div>
                <div class="text-900">
                    {{user?.name_}}
                </div>
            </div>
            <div class="col-12 md:col-6 p-3">
                <div class="text-500 font-medium mb-2">
                    Level
                </div>
                <div class="text-900">
                    {{user?.level_}}
                </div>
            </div>
            <div class="col-12 md:col-6 p-3">
                <div class="text-500 font-medium mb-2">
                    Phone
                </div>
                <div class="text-900">
                    {{user?.phone}}
                </div>
            </div>
            <div class="col-12 md:col-6 p-3">
                <div class="text-500 font-medium mb-2">
                    Email
                </div>
                <div class="text-900">
                    {{user?.email}}
                </div>
            </div>
            <div class="col-12 md:col-6 p-3">
                <Avatar :image="user?.avatar_img ? avatar_img : '/photo-profile-default.jpg'" class="mr-2" size="xlarge" shape="circle" />
            </div>
        </div>
    </BlockUI>
</template>

<script>
export default {
    props: [
		"id",
    ],
    data() {
        return {
            loading: false,
            loadingCard: false,

            user: null,
        }
    },
    mounted() {
        this.getData();
        this.$store.commit('setErrors', {});
    },
    methods:{
        getData(){

            this.loadingCard=true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/user/detail',
                params: {
                    "userid": this.id,
                }
            })
            .then(res => {
                this.user = res.data.data;

                this.loadingCard= false;
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
    }
}
</script>