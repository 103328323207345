<template>
	<Error :errors="errors"/>

	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card ">
                <div class="font-medium text-3xl text-900 mb-3">PROFILE USER</div>
                <div class="text-500 mb-5">Berikut merupakan detail profile user</div>
				<ProfileUser :id="id"/>
			</div>
        </div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card ">
				<div class="font-medium text-3xl text-900 mb-3">MJP</div>
				<MJP :id="id"/>
			</div>
        </div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card">
				<div class="font-medium text-3xl text-900 mb-3">ARCHIVE</div>
				<Archive :id="id"/>
			</div>
        </div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileUser from '../../../components/data/user/ProfileUser.vue';
import MJP from '../../../components/data/user/MJP.vue';
import Archive from '../../../components/data/user/archive/Index.vue';
import Error from '../../../components/Error.vue';

export default {
	components: {
		'Error': Error,
		'ProfileUser': ProfileUser,
		'MJP': MJP,
		'Archive': Archive,
	},
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['errors']),
        id(){ 
            return this.$route.params.id; 
        }
    },
}
</script>